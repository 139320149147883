import { Badge, Notification, toast } from "components/ui";
import { ORDER_STATUS } from "constants/status.constant";
import { useField } from "formik";
import * as _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";

export function processParams(url, params = {}, useURLSearchParams = true) {
  if (!Object.keys(params).length) {
    return url;
  }

  if (useURLSearchParams) {
    // eslint-disable-next-line no-return-assign
    return (url += `?${new URLSearchParams(params)}`);
  }

  Object.keys(params).forEach((key, index) => {
    const prefix = index ? "&" : "?";
    if (isNotNullOrUndefined(params[key])) {
      url += `${prefix}${key}=${params[key]}`;
    }
  });
  return url;
}

export function isNotNullOrUndefined(data) {
  return data !== null && data !== undefined;
}

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === "";
}

export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return "";
  }
  let format = "$1,";
  return `${digits ? money : Math.trunc(+money)}`.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    format,
  );
}

export function formatDate(value, format, oldFormat) {
  if (!value) return "";
  if (oldFormat) {
    return moment(value, oldFormat)
      .local()
      .format(format || "DD/MM/YYYY");
  } else {
    return moment(value)
      .local()
      .format(format || "DD/MM/YYYY");
  }
}

export function formatDateTime(value, format) {
  const result = value
    ? moment(value)
        .local()
        .format(format || "DD/MM/YYYY HH:mm:ss")
    : "";
  return result;
}

export function formatSecondToMinute(value) {
  let date = new Date(0);
  date.setSeconds(Number(value) / 1000);
  return date.toISOString().substring(11, 19);
}

export function addNo(list) {
  let result = [];
  if (list && list.length > 0) {
    list.forEach((item, index) => {
      result.push({
        stt: index + 1,
        ...item,
      });
    });
  }
  return result;
}

function readGroup(group) {
  let readDigit = [
    " Không",
    " Một",
    " Hai",
    " Ba",
    " Bốn",
    " Năm",
    " Sáu",
    " Bảy",
    " Tám",
    " Chín",
  ];
  var temp = "";
  if (group == "000") return "";
  temp = readDigit[parseInt(group.substring(0, 1))] + " Trăm";
  if (group.substring(1, 2) == "0")
    if (group.substring(2, 3) == "0") return temp;
    else {
      temp += " Lẻ" + readDigit[parseInt(group.substring(2, 3))];
      return temp;
    }
  else temp += readDigit[parseInt(group.substring(1, 2))] + " Mươi";
  if (group.substring(2, 3) == "5") temp += " Lăm";
  else if (group.substring(2, 3) != "0")
    temp += readDigit[parseInt(group.substring(2, 3))];
  return temp;
}

export function readMoney(num) {
  if (num == null || num == "") return "";
  let temp = "";
  while (num.length < 18) {
    num = "0" + num;
  }
  let g1 = num.substring(0, 3);
  let g2 = num.substring(3, 6);
  let g3 = num.substring(6, 9);
  let g4 = num.substring(9, 12);
  let g5 = num.substring(12, 15);
  let g6 = num.substring(15, 18);
  if (g1 != "000") {
    temp = readGroup(g1);
    temp += " Triệu";
  }
  if (g2 != "000") {
    temp += readGroup(g2);
    temp += " Nghìn";
  }
  if (g3 != "000") {
    temp += readGroup(g3);
    temp += " Tỷ";
  } else if ("" != temp) {
    temp += " Tỷ";
  }
  if (g4 != "000") {
    temp += readGroup(g4);
    temp += " Triệu";
  }
  if (g5 != "000") {
    temp += readGroup(g5);
    temp += " Nghìn";
  }
  temp = temp + readGroup(g6);
  temp = temp.replaceAll("Một Mươi", "Mười");
  temp = temp.trim();
  temp = temp.replaceAll("Không Trăm", "");
  temp = temp.trim();
  temp = temp.replaceAll("Mười Không", "Mười");
  temp = temp.trim();
  temp = temp.replaceAll("Mươi Không", "Mươi");
  temp = temp.trim();
  if (temp.indexOf("Lẻ") == 0) temp = temp.substring(2);
  temp = temp.trim();
  temp = temp.replaceAll("Mươi Một", "Mươi Mốt");
  temp = temp.trim();
  let result =
    temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase();
  return (result == "" ? "Không" : result) + " đồng chẵn";
}

export const showWarning = (msg) => {
  return Swal.fire({
    icon: "warning",
    title: msg.replace(/\[ERR_[0-9]+\]/g, ""),
  });
};

export const showError = (msg) => {
  console.log("error", msg);
};

export const showErrorAlert = (msg, text = "") => {
  toast.push(
    <Notification title={"Lỗi"} type="danger" duration={2500}>
      {msg.replace(/\[ERR_[0-9]+\]/g, "")}
    </Notification>,
    {
      placement: "top-center",
    },
  );
};
export const showWarningAlert = (msg, text = "") => {
  toast.push(
    <Notification title={"Chú ý"} type="warning" duration={2500}>
      {msg.replace(/\[ERR_[0-9]+\]/g, "")}
    </Notification>,
    {
      placement: "top-center",
    },
  );
};

export const showNetworkError = () => {
  return showErrorAlert("Vui lòng kiểm tra lại kết nối");
};

export const showConFirmAlertInput = (msg, typeInput, reason, isShowDeny) => {
  return Swal.fire({
    title: msg,
    input: typeInput,
    inputPlaceholder: reason,
    returnInputValueOnDeny: true,
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#005aa6",
    cancelButtonColor: !isShowDeny ?? "#d33",
    confirmButtonText: "Đồng ý",
    cancelButtonText: "Huỷ bỏ",
    denyButtonText: "Từ chối",
    showDenyButton: isShowDeny,
  });
};

export const showConFirmAlertRedirect = () => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success colorButton",
      denyButton: "btn btn-danger colorButton",
    },
    buttonsStyling: false,
  });
  return swalWithBootstrapButtons.fire({
    title: "Tạo đề nghị tạm ứng / thanh toán",
    icon: "question",
    showDenyButton: true,
    showCancelButton: false,
    confirmButtonText: "Tạo đề nghị tạm ứng",
    denyButtonText: `Tạo đề nghị thanh toán`,
  });
};

export const showSuccessAlert = (msg, duration = 2500) => {
  toast.push(
    <Notification type="success" title="Thông báo" duration={duration}>
      {msg.replace(/\[ERR_[0-9]+\]/g, "")}
    </Notification>,
    {
      placement: "top-center",
    },
  );
};
export const showConfirmAlert = (msg, icon) => {
  return Swal.fire({
    text: msg,
    icon: icon,
    showCancelButton: true,
    confirmButtonColor: "#005aa6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Đồng ý",
    cancelButtonText: "Hủy bỏ",
  });
};

export const showNotifyAlert = (icon, title, text, confirmButtonText) => {
  return Swal.fire({
    icon,
    title,
    text,
    confirmButtonText,
  });
};

export const showNotifySuccess = (position, icon, title, showConfirmButton) => {
  Swal.fire({
    position,
    icon,
    title,
    showConfirmButton: false,
    timer: 1500,
  });
};

export const removeAccents = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " ",
  );
  return str;
};

export const to_slug = (str) => {
  // Chuyển hết sang chữ thường
  str = str?.toLowerCase();

  // xóa dấu
  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
  str = str.replace(/(đ)/g, "d");

  // Xóa ký tự đặc biệt
  str = str.replace(new RegExp(/([^0-9a-z-\s])/g), "");

  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(new RegExp(/(\s+)/g), "-");

  // xóa phần dự - ở đầu
  str = str.replace(new RegExp(/^-+/g), "");

  // xóa phần dư - ở cuối
  str = str.replace(new RegExp(/-+$/g), "");

  // return
  return str;
};

export const showPrice = (value) => {
  if (value) {
    return value
      .toString()
      .replace(new RegExp(/(\d)(?=(\d{3})+(?!\d))/g), "$1.");
  }
  return 0;
};

export const readFileToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

// Xóa  phần tử trùng trong mảng
export function uniqueElement(arr) {
  var newArr = [];
  for (var i = 0; i < arr.length; i++) {
    if (!newArr.includes(arr[i])) {
      newArr.push(arr[i]);
    }
    return newArr;
  }
}

export const uniqueArray = (arr) => {
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.value !== arr[i - 1]?.value) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
};

export const nullToStr = (value) => (value === null ? "" : value);

export const unicodeToChar = (text) => {
  return text.replace(/\\u[\dA-F]{4}/gi, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
  });
};
export const FileUpload = ({ fileRef, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label htmlFor="files">Choose files</label>{" "}
      <input ref={fileRef} multiple={true} type="file" {...field} />
      {meta.touched && meta.error ? (
        <div style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export function onKeyDown(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

const transactionCode = {
  receive_send: {
    no: "01",
    code: "AZ",
  },
  send_money: {
    no: "02",
    code: "LD",
  },
  buy: {
    no: "05",
    code: "MM",
  },
  sell: {
    no: "06",
    code: "MM",
  },
  borrow: {
    no: "03",
    code: "AZ",
  },
  lending: {
    no: "04",
    code: "LD",
  },
};
export const convertTransactionCode = (type) => {
  return `${transactionCode[type].code}.${moment().format("DDMMYYYY")}`;
};

export const convertAmountTransaction = (amount = "") => {
  return amount.replace(/\,/g, "");
};

export const openNotification = (type, message) => {
  toast.push(
    <Notification
      title={type.charAt(0).toUpperCase() + type.slice(1)}
      type={type}
    >
      {message}
    </Notification>,
  );
};

export function dataURLtoFile(dataUrl, filename) {
  let arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false",
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};

export const isHollow = (value) => {
  if (value && Array.isArray(value)) {
    return value.length === 0;
  }
  if (value && typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return value === undefined || value === null || value === "";
};

export const isValidUrl = (url) => {
  const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return pattern.test(url);
};

export function formatDateFromStr(value, strFormat, format) {
  const result = value
    ? moment(value, strFormat)
        .local()
        .format(format || "DD/MM/YYYY")
    : "";
  return result;
}

export const searchFilter = (search) => {
  search = _.omitBy(search, (v) => !v);
  let filter = {};
  for (const [key, value] of Object.entries(search)) {
    if (
      search[key]?.hasOwnProperty("fromDate") &&
      search[key]?.hasOwnProperty("toDate")
    ) {
      if (search[key].fromDate || search[key].toDate) {
        filter["dateFilter[field]"] = key;
        search[key].fromDate &&
          (filter["dateFilter[fromDate]"] = value.fromDate);
        search[key].toDate && (filter["dateFilter[toDate]"] = value.toDate);
      }
    } else {
      filter[`filter[${key}]`] = value;
    }
  }
  return filter;
};

const getCoup = (settlement, maturity, frequency) => {
  const nearMaturity = moment(maturity).set("year", moment(settlement).year());

  if (nearMaturity.isBefore(settlement)) {
    nearMaturity.add(1, "year");
  }

  while (nearMaturity.isAfter(settlement)) {
    nearMaturity.subtract(12 / frequency, "months");
  }

  return nearMaturity;
};

export const coupDayBs = (settlement, maturity, frequency, basis) => {
  const _settlement = moment(settlement);
  const _maturity = moment(maturity);
  return _settlement.diff(getCoup(_settlement, _maturity, frequency), "days");
};

export const getDifferencesDays = (firstDate, secondDate, isAbs = true) => {
  const fromDate = moment(firstDate);
  const toDate = moment(secondDate);
  const differencesDays = fromDate.diff(toDate, "days");
  return isAbs ? Math.abs(differencesDays) : differencesDays;
};

export const downloadFile = (content, fileName) => {
  let uriContent = URL.createObjectURL(
    new Blob([content], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
  );
  let link = document.createElement("a");
  link.setAttribute("href", uriContent);
  link.setAttribute(
    "download",
    `${fileName + moment().format("DDMMYYYYhhmmss")}.xlsx`,
  );
  let event = new MouseEvent("click");
  link.dispatchEvent(event);
};

export const StatusStyleColor = (status, LOAN_STATUS) => {
  const list = LOAN_STATUS.filter((loanStatus) => loanStatus.VALUE === status);
  if (list && list[0]) {
    return list[0].STYLE;
  }

  return {
    //Default
    label: status,
    dotClass: "bg-zinc-400",
    textClass: "text-inherit",
  };
};

export const showMoneyAndCurrency = (money, ccy) => {
  return <div className="text-right">{`${formatCurrency(money)} ${ccy}`}</div>;
};

export const showMoney = (money) => {
  return <div className="text-right">{`${formatCurrency(money)}`}</div>;
};

export const showCustomerName = (cif, custName) => {
  return `${cif} - ${custName}`;
};

export const showTextRight = (value) => {
  return <div className="text-right">{value}</div>;
};

export const showTextCenter = (value) => {
  return <div className="text-center">{value}</div>;
};

export const showDate = (value) => {
  const date = formatDate(value);
  return showTextCenter(date);
};

export const showStatus = (style) => {
  return (
    <div className="flex items-center gap-2">
      <Badge className={style?.dotClass} />
      <span className={`font-semibold ${style?.textClass}`}>
        {style?.label}
      </span>
    </div>
  );
};

export const showDateTime = (value) => {
  const date = formatDateTime(value);
  return showTextCenter(date);
};

export const showErrorMessage = (message) => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
    footer: "",
  });
};

export const showEmsUrl = (postCode) => {
  const link = (
    <a
      target="_blank"
      style={{ color: "#c36" }}
      href={`https://ems.com.vn/tra-cuu/tra-cuu-buu-gui?code=${postCode}`}
    >
      {postCode}
    </a>
  );
  return postCode ? showTextCenter(link) : "";
};

export const showVTPUrl = (postCode) => {
  const link = (
    <a
      target="_blank"
      style={{ color: "#c36" }}
      href={`https://tracking.tatrove.com/tracking/${postCode}`}
    >
      {postCode}
    </a>
  );
  return postCode ? showTextCenter(link) : "";
};

export const showBasicVTPUrl = (postCode) => {
  const link = (
    <a
      target="_blank"
      style={{ color: "#c36" }}
      href={`https://tracking.tatrove.com/basic-tracking?postCode=${postCode}&source=TATROVE_COM`}
    >
      {postCode}
    </a>
  );
  return postCode ? showTextCenter(link) : "";
};

export const getOrderStatus = (statusCode) => {
  const status = _.findLast(ORDER_STATUS, { statusCode: statusCode });
  return status;
};

export const showOrderStatus = (statusCode) => {
  const status = getOrderStatus(statusCode);
  if (!status) return "";
  return (
    <div className={`${status?.colorClassName ?? ""} font-semibold`}>
      {status.name}
    </div>
  );
};

export const showOrderNote = (orderNote) => {
  if (Array.isArray(orderNote) && orderNote.length > 0) {
    return (
      <div>
        <ul>
          {orderNote.map((item) => {
            return (
              <li>
                <span class="note-date">
                  {formatDateTime(item?.createdAt)} - {item?.createdBy}:{" "}
                </span>
                <span>{item?.note}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return "";
};
