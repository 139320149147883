export const SIZES = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
};

export const CONTROL_SIZES = {
  [SIZES.XS]: 7,
  [SIZES.SM]: 9,
  [SIZES.MD]: 11,
  [SIZES.LG]: 14,
};

export const LAYOUT = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
  INLINE: "inline",
};

export const DIRECTIONS = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
};

export const SELECTION_MODES = {
  YEAR: "year",
  MONTH: "month",
  DAY: "day",
};

export const PICKER_VIEWS = {
  YEAR: "year",
  MONTH: "month",
  DATE: "date",
};

export const STATUS = {
  DANGER: "danger",
  SUCCESS: "success",
  WARNING: "warning",
};

export const STEPS_STATUS = {
  COMPLETE: "complete",
  PENDING: "pending",
  IN_PROGRESS: "in-progress",
  ERROR: "error",
};

export const PLACEMENT = {
  TOP_START: "top-start",
  TOP_CENTER: "top-center",
  TOP_END: "top-end",
  BOTTOM_START: "bottom-start",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_END: "bottom-end",
  MIDDLE_START_TOP: "middle-start-top",
  MIDDLE_START_BOTTOM: "middle-start-bottom",
  MIDDLE_END_TOP: "middle-end-top",
  MIDDLE_END_BOTTOM: "middle-end-bottom",
};

export const DROPDOWN_ITEM_TYPE = {
  DEFAULT: "default",
  HEADER: "header",
  DIVIDER: "divider",
  CUSTOM: "custom",
};

export const DAY_DURATION = 86400000;

export const ContractStatus = {
  CREATED: "CREATED",
  PRINTED: "PRINTED",
  APPROVING: "APPROVING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  SUBMITTED: "SUBMITTED",
  REVERTED: "REVERTED",
  SUBMITTING: "SUBMITTING",
};

export const fxTransactionType = [
  { value: "SPB_BUY", title: "Mua giao ngay", desc: "Dịch vụ SP01" },
  { value: "SPS_SELL", title: "Bán giao ngay", desc: "Dịch vụ SP02" },
  { value: "FWB_BUY", title: "Mua kỳ hạn", desc: "Dịch vụ FW01" },
  { value: "FWS_SELL", title: "Bán kỳ hạn", desc: "Dịch vụ FW02" },
  { value: "SWB_BUY", title: "Hoán đổi mua bán", desc: "Dịch vụ SW01" },
  { value: "SWS_SELL", title: "Hoán đổi bán mua", desc: "Dịch vụ SW02" },
];

export const bondTransactionType = [
  { value: "GB_BUY", title: "Mua TPCP", desc: "Dịch vụ GB" },
  { value: "GB_SELL", title: "Bán TPCP", desc: "Dịch vụ GB" },
  { value: "CI_BUY", title: "Mua GTCG TCTD", desc: "Dịch vụ CI" },
  { value: "CI_SELL", title: "Bán GTCG TCTD", desc: "Dịch vụ CI" },
];

export const fxContractDueStatus = [
  { value: "ALL", label: "Tất cả" },
  { value: "CREATE", label: "Chưa hoàn tất chuyển tiền" },
  { value: "PROCESS", label: "Đang chuyển tiền" },
  { value: "DONE", label: "Đã hoàn tất chuyển tiền" },
  { value: "SUBMITTED", label: "Đã đẩy T24" },
];

export const tmPaymentDueStatus = [
  { value: "ALL", label: "Tất cả" },
  { value: "CREATED", label: "Chưa duyệt" },
  { value: "CONFIRMED", label: "Đã xác nhận" },
  { value: "APPROVED", label: "Đã duyệt" },
  { value: "REJECTED", label: "Đã từ chối" },
];

export const SPECIAL_BANK_TYPE = ["NOSTRO"];

export const ACCOUNT_TYPE_OPTIONS = [
  { value: "WA", label: "WA" },
  { value: "CKPT_TP", label: "CKPT_TP" },
  { value: "CKPT_TCTD", label: "CKPT_TCTD" },
  { value: "LDT_TP", label: "LDT_TP" },
  { value: "LDT_TCTD", label: "LDT_TCTD" },
  { value: "NOSTRO", label: "NOSTRO" },
  { value: "LO_BAN", label: "LO_BAN" },
  { value: "LAI_BAN", label: "LAI_BAN" },
  { value: "PB_CKPT", label: "PB_CKPT" },
];

export const OPTION_CURRENCY = [
  { value: "", label: "Chọn" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "CHF", label: "CHF" },
  { value: "CNY", label: "CNY" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "HKD", label: "HKD" },
  { value: "JPY", label: "JPY" },
  { value: "SGD", label: "SGD" },
  { value: "USD", label: "USD" },
  { value: "VND", label: "VND" },
  { value: "XAU", label: "XAU" },
];

export const ARR_PRODUCT = [
  { value: "", label: "Chọn" },
  { value: "MM", label: "GD Liên ngân hàng" },
  { value: "FX", label: "KD Ngoại tệ" },
  { value: "BOND", label: "KD GTCG" },
  { value: "HDV", label: "HĐV TN" },
  { value: "TINDUNG", label: "Tín dụng" },
  { value: "EBANKING", label: "Internet Banking" },
];

export const LOAN_ACTION = {
  //trình duyệt || phê duyệt
  APPROVED: "APPROVED", // đồng ý
  REJECTED: "REJECTED", // từ chối
  NOT_CONSIDERING: "NOT_CONSIDERING", //chưa xem xét
  //yêu cầu điều chỉnh bổ sung
  EDIT: "EDIT", // điều chỉnh / bổ sung
  EDIT_PERMISSION: "EDIT_PERMISSION", // điều chỉnh thẩm quyền
  //yêu cầu tái thẩm định
  CHECK_APPLICATION: "CHECK_APPLICATION", // kiểm tra hồ sơ
  ASSIGNMENT: "ASSIGNMENT", // phân công xử lý
  REASSESSMENT: "REASSESSMENT", //tái thẩm định
  //trả hồ sơ
  RETURNED_CUSTOMER: "RETURNED_CUSTOMER", // trả hồ sơ khách hàng
  RETURNED_DVKD: "RETURNED_DVKD", // trả hồ sơ đơn vị kd
};

export const ApprovalStatus = {
  CREATED: "CREATED", // mới tạo
  APPROVING: "APPROVING", // CHỜ DUYỆT
  APPROVED: "APPROVED", // đã duyệt
  REJECTED: "REJECTED", // từ chối
  RETURNED: "RETURNED", // trả hồ sơ
  CANCEL: "CANCEL",
};

export const LoanStatus = {
  CREATED: "CREATED",
  EDIT: "EDIT", // điều chỉnh / bổ sung
  EDIT_PERMISSION: "EDIT_PERMISSION", // điều chỉnh thẩm quyền
  APPROVED: "APPROVED", // đồng ý
  REJECTED: "REJECTED", // từ chối
  RETURNED_CUSTOMER: "RETURNED_CUSTOMER", // trả hồ sơ khách hàng
  RETURNED_DVKD: "RETURNED_DVKD", // trả hồ sơ đơn vị kd
  NOT_CONSIDERING: "NOT_CONSIDERING", // không xem xét
  TRANSFER_RA: "TRANSFER_RA", //chuyển tái thẩm định
  RA_ASSIGNED: "RA_ASSIGNED", //đã phân công TTĐ
  REASSESSED: "REASSESSED", //đã tái thẩm định
  RELEASED_RA_REPORT: "RELEASED_RA_REPORT", //đã phát hành báo cáo TTĐ
  APPROVED_RA_REPORT: "APPROVED_RA_REPORT", //đã duyệt báo cáo TTĐ,
  EDIT_COMPLETION: "EDIT_COMPLETION", //hoàn thành điều chỉnh/bổ sung
  DISBURSED: "DISBURSED", // đã giải ngân
};

export const ApprovalButton = {
  DELETE: "DELETE", //xóa
  EDIT: "EDIT", //sửa
  SUBMIT_APPROVAL: "SUBMIT_APPROVAL", //trình duyệt
  REQUEST_ADJUSTMENT: "REQUEST_ADJUSTMENT", //yêu cầu điều chỉnh/bổ sung
  COMPLETE_ADJUSTMENT: "COMPLETE_ADJUSTMENT", //hoàn thành điều chỉnh/bổ sung
  REQUEST_REASSESSMENT: "REQUEST_REASSESSMENT", //yêu cầu tái thẩm định
  ASSIGNMENT: "ASSIGNMENT", //phân công xử lý
  APPROVAL: "APPROVAL", //phê duyệt
  RETURN_APPLICATION: "RETURN_APPLICATION", //trả hồ sơ
  FORWARD_BOARD_MEMBERS: "FORWARD_BOARD_MEMBERS", //chuyển hội đồng
  RELEASE_RA_REPORT: "RELEASE_RA_REPORT", //phát hành báo cáo TTĐ
  APPROVE_RA_REPORT: "APPROVE_RA_REPORT", //duyệt báo cáo TTĐ
  COPY: "COPY", //nhân bản
  OPINION: "OPINION", //cho ý kiến
};
