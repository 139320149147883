import axios from "axios";
import { Notification, toast } from "components/ui";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";

const unauthorizedCode = [401];

const HttpService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_API,
});

HttpService.interceptors.request.use(
  (config) => {
    config.startTime = Date.now();
    const state = store.getState();
    const { token } = state.auth.session;
    if (!config?.public && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

HttpService.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
      toast.push(
        <Notification type="warning" title="Đăng xuất">
          Tài khoản đã hết phiên đăng nhập! Vui lòng đăng nhập lại.
        </Notification>,
      );
    }
    return Promise.reject(error);
  },
);

export default HttpService;
