import { combineReducers } from "redux";
import auth from "./auth";
import base from "./base";
import common from "./common";
import locale from "./locale/localeSlice";
import notification from "./notification";
import theme from "./theme/themeSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    common,
    notification,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
