import ConfigProvider, {
  ConfigConsumer,
  ConfigContext,
  defaultConfig,
  useConfig,
} from "./ConfigProvider";

export { ConfigContext, ConfigConsumer, useConfig, defaultConfig };

export default ConfigProvider;
