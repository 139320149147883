import { APP_ROUTE } from "constants/route.constant";

const appConfig = {
  apiPrefix: "/mobile-base-web",
  authenticatedEntryPath: APP_ROUTE,
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  locale: "vi",
  enableMock: true,
};

export default appConfig;
