import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

const Divider = ({ label = "", isExpanded = false, hasIcon = false }) => (
  <div className="divider flex w-full justify-start">
    {label && (
      <h4>
        {hasIcon && (
          <FontAwesomeIcon
            style={{ marginRight: "12px" }}
            size="xs"
            icon={isExpanded ? faChevronDown : faChevronRight}
          />
        )}
        {label}
      </h4>
    )}
  </div>
);

export default Divider;
