import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification/auth",
  initialState: {
    message: "Test",
    token: "Test",
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setMessage, setToken } = notificationSlice.actions;

export default notificationSlice.reducer;
